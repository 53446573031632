<template>
  <div class="pbz-font d-flex flex-column justify-content-center p-5">
    <div class="d-flex justify-content-center">
      <h3>Wallet Distributor</h3>
    </div>

    <div :class="$style.walletWithdrawl">
      <div class="d-flex justify-content-center">
        <h5>Masukkan Data Anda</h5>
      </div>

      <div class="my-2 d-flex flex-column" :class="$style.divider">
        <h6>Bank Akun</h6>
        <a-row class="my-4">
          <a-col :span="12">
            <a-select
              placeholder="Pilih Akun Bank"
              option-label-prop="label"
              style="width: 70%"
            >
              <a-select-option
                v-for="(item, index) in bank_list"
                :key="index"
                :value="item.name"
                :label="item.name"
              >
                <div>
                  {{ item.name }}
                </div>
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="12">
            <a-button :class="$style.positiveBtn" @click="handleAddBank(true)">
              Tambah Bank
            </a-button>
          </a-col>
        </a-row>
      </div>

      <div class="my-4">
        <h3 style="color: #e00">
          Total Saldo Rp. 25.000.000
        </h3>
      </div>

      <div class="mt-5">
        <a-row>
          <a-col :span="12">
            <h4>Jumlah Penarikan</h4>
          </a-col>
          <a-col :span="12">
            <a-input class="mb-3" />
            <p>Minimal Penarikan Rp. xxxxxxx</p>
            <p>Biaya Penarikan Rp. xxxxxxx</p>
          </a-col>
        </a-row>
      </div>

      <div class="d-flex justify-content-center mt-5">
        <a-checkbox>Semua data sudah sesuai</a-checkbox>
      </div>

      <div class="mt-5">
        <a-col :span="12" class="d-flex justify-content-center">
          <a-button :class="$style.positiveBtn">
            Ok
          </a-button>
        </a-col>
        <a-col :span="12" class="d-flex justify-content-center">
          <a-button :class="$style.negativeBtn">
            Batal
          </a-button>
        </a-col>
      </div>
      <AddBank 
        v-if="modalAddBank"
        :bank-list="bank_list"
        @handleAddBank="handleAddBank"
      />
    </div>
  </div>
</template>

<script>
import AddBank from './partials/AddBank.vue'
import withdrawl from './composables/withdrawl'

export default {
  components: {
    AddBank,
  },
  setup() {
    const { handleAddBank, modalAddBank, bank_list } = withdrawl()

    return { handleAddBank, modalAddBank, bank_list }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>