import { reactive, toRefs } from 'vue'

export default () => {
  const state_ = reactive({
    history_list: [
      {
      withdrawl_date: '20 Oktober 2022',
      nominal: 'Rp. 250.000',
      state: 'On Process',
      },
      {
      withdrawl_date: '25 Agustus 2022',
      nominal: 'Rp. 550.000',
      state: 'Done',
      },
      {
      withdrawl_date: '20 Juni 2022',
      nominal: 'Rp. 150.000',
      state: 'Done',
      },
    ],
    bank_list: [
      {
        name: 'Mandiri',
      },
      {
        name: 'BCA',
      },
      {
        name: 'BNI',
      },
      {
        name: 'BRI',
      },
      {
        name: 'BSI',
      },
    ],
    modalAddBank: false,
  })

  const handleAddBank = (param) => {
    state_.modalAddBank = param
  }

  return { ...toRefs(state_), handleAddBank }
}