<template>
  <a-modal
    :visible="true"
    :footer="null"
    @cancel="() => $emit('handleAddBank', false)"
  >
    <div class="d-flex justify-content-center flex-column p-4" :class="$style.modalAddBank">
      <div class="d-flex justify-content-center mb-4 ">
        Tambah Bank Penerima
      </div>

      <div class="my-2">
        <a-col :span="12">
          Nama Bank
        </a-col>
        <a-col :span="12">
          <a-select
            placeholder="Pilih Akun Bank"
            option-label-prop="label"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in bankList"
              :key="index"
              :value="item.name"
              :label="item.name"
            >
              <div>
                {{ item.name }}
              </div>
            </a-select-option>
          </a-select>
        </a-col>
      </div>

      <div class="my-2">
        <a-col :span="12">
          Nomor Akun
        </a-col>
        <a-col :span="12">
          <a-input />
        </a-col>
      </div>

      <div class="my-2">
        <a-col :span="12">
          Nama Akun
        </a-col>
        <a-col :span="12">
          <a-input />
        </a-col>
      </div>

      <div class="mt-4">
        <a-col :span="12" class="d-flex justify-content-center">
          <a-button :class="$style.positiveBtn">
            Ok
          </a-button>
        </a-col>
        <a-col :span="12" class="d-flex justify-content-center">
          <a-button :class="$style.negativeBtn" @click="() => $emit('handleAddBank', false)">
            Batal
          </a-button>
        </a-col>
      </div>
    </div>
  </a-modal>
</template>

<script>

export default {
  props: {
      bankList: {
        type: Array,
        default: () => [],
      },
  },
}
</script>

<style lang="scss" module>
@import '../style.module.scss';
</style>